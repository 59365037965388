let letterList = function () {
    return [
        {
            name: 'A',
            idx: 1
        },
        {
            name: 'B',
            idx: 2
        },
        {
            name: 'C',
            idx: 3
        },
        {
            name: 'D',
            idx: 4
        },
        {
            name: 'E',
            idx: 5
        },
        {
            name: 'F',
            idx: 6
        },
        {
            name: 'G',
            idx: 7
        },
        {
            name: 'H',
            idx: 8
        },
        {
            name: 'I',
            idx: 9
        },
        {
            name: 'J',
            idx: 10
        },
        {
            name: 'K',
            idx: 11
        },
        {
            name: 'L',
            idx: 12
        },
        {
            name: 'M',
            idx: 13
        },
        {
            name: 'N',
            idx: 14
        },
        {
            name: 'O',
            idx: 15
        },
        {
            name: 'P',
            idx: 16
        },
        {
            name: 'Q',
            idx: 17
        },
        {
            name: 'R',
            idx: 18
        },
        {
            name: 'S',
            idx: 19
        },
        {
            name: 'T',
            idx: 20
        },
        {
            name: 'U',
            idx: 21
        },
        {
            name: 'V',
            idx: 22
        },
        {
            name: 'W',
            idx: 23
        },
        {
            name: 'X',
            idx: 24
        },
        {
            name: 'Y',
            idx: 25
        },
        {
            name: 'Z',
            idx: 26
        }, {
            name: 'A',
            idx: 27
        },
        {
            name: 'B',
            idx: 28
        },
        {
            name: 'C',
            idx: 29
        },
        {
            name: 'D',
            idx: 30
        },
        {
            name: 'E',
            idx: 31
        },
        {
            name: 'F',
            idx: 32
        },
        {
            name: 'G',
            idx: 33
        },
        {
            name: 'H',
            idx: 34
        },
        {
            name: 'I',
            idx: 35
        },
        {
            name: 'J',
            idx: 36
        },
        {
            name: 'K',
            idx: 37
        },
        {
            name: 'L',
            idx: 38
        },
        {
            name: 'M',
            idx: 39
        },
        {
            name: 'N',
            idx: 40
        },
        {
            name: 'O',
            idx: 41
        },
        {
            name: 'P',
            idx: 42
        },
        {
            name: 'Q',
            idx: 43
        },
        {
            name: 'R',
            idx: 44
        },
        {
            name: 'S',
            idx: 45
        },
        {
            name: 'T',
            idx: 46
        },
        {
            name: 'U',
            idx: 47
        },
        {
            name: 'V',
            idx: 48
        },
        {
            name: 'W',
            idx: 49
        },
        {
            name: 'X',
            idx: 50
        },
        {
            name: 'Y',
            idx: 51
        },
        {
            name: 'Z',
            idx: 52
        }, {
            name: 'A',
            idx: 53
        },
        {
            name: 'B',
            idx: 54
        },
        {
            name: 'C',
            idx: 55
        },
        {
            name: 'D',
            idx: 56
        },
        {
            name: 'E',
            idx: 57
        },
        {
            name: 'F',
            idx: 58
        },
        {
            name: 'G',
            idx: 59
        },
        {
            name: 'H',
            idx: 60
        },
        {
            name: 'I',
            idx: 61
        },
        {
            name: 'J',
            idx: 62
        },
        {
            name: 'K',
            idx: 63
        },
        {
            name: 'L',
            idx: 64
        },
        {
            name: 'M',
            idx: 65
        },
        {
            name: 'N',
            idx: 66
        },
        {
            name: 'O',
            idx: 67
        },
        {
            name: 'P',
            idx: 68
        },
        {
            name: 'Q',
            idx: 69
        },
        {
            name: 'R',
            idx: 70
        },
        {
            name: 'S',
            idx: 71
        },
        {
            name: 'T',
            idx: 72
        },
        {
            name: 'U',
            idx: 73
        },
        {
            name: 'V',
            idx: 74
        },
        {
            name: 'W',
            idx: 75
        },
        {
            name: 'X',
            idx: 76
        },
        {
            name: 'Y',
            idx: 77
        },
        {
            name: 'Z',
            idx: 78
        }, {
            name: 'A',
            idx: 79
        },
        {
            name: 'B',
            idx: 80
        },
        {
            name: 'C',
            idx: 81
        },
        {
            name: 'D',
            idx: 82
        },
        {
            name: 'E',
            idx: 83
        },
        {
            name: 'F',
            idx: 84
        },
        {
            name: 'G',
            idx: 85
        },
        {
            name: 'H',
            idx: 86
        },
        {
            name: 'I',
            idx: 87
        },
        {
            name: 'J',
            idx: 88
        },
        {
            name: 'K',
            idx: 89
        },
        {
            name: 'L',
            idx: 90
        },
        {
            name: 'M',
            idx: 91
        },
        {
            name: 'N',
            idx: 92
        },
        {
            name: 'O',
            idx: 93
        },
        {
            name: 'P',
            idx: 94
        },
        {
            name: 'Q',
            idx: 95
        },
        {
            name: 'R',
            idx: 96
        },
        {
            name: 'S',
            idx: 97
        },
        {
            name: 'T',
            idx: 98
        },
        {
            name: 'U',
            idx: 99
        },
        {
            name: 'V',
            idx: 100
        },
        {
            name: 'W',
            idx: 101
        },
        {
            name: 'X',
            idx: 102
        },
        {
            name: 'Y',
            idx: 103
        },
        {
            name: 'Z',
            idx: 104
        },
    ]
}

export default letterList

