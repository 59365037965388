<template>
  <div class="alarmTemplate">
    <div class="title">
      <p>
        <span>告警模版</span>
      </p>
    </div>
    <!--列表-->
    <div class="content">
      <div class="contain">
        <!--新增模版-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增模版
        </el-button>
        <!--模版列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="模版名称" prop="name"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="创建人" prop="creatorName"></el-table-column>
          <el-table-column label="告警规则" prop="targetDList">
            <template #default="scope">
              <p v-for="(item,index) in scope.row.targetDList" :key="index">
                <span v-if="item.level===1" class="level level1">{{ item.levelStr }}</span>
                <span v-if="item.level===2" class="level level2">{{ item.levelStr }}</span>
                <span v-if="item.level===3" class="level level3">{{ item.levelStr }}</span>
                <span v-if="item.level===4" class="level level4">{{ item.levelStr }}</span>
                <span v-if="item.level===5" class="level level5">{{ item.levelStr }}</span>
                {{ item.dictName }}{{ item.symbol }}{{ item.value }}
              </p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120px">
            <template #default="scope">
              <div class="opacity">
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass delBtn" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableData.length>0" class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑模版':'新建模版'"
               width="80%">
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <el-form-item label="模版名称:" prop="name">
          <el-input v-model="formData.name" placeholder="请输入模版名称"></el-input>
        </el-form-item>
        <div v-for="(item,index) in formData.targetJsonStr" :key="index" class="rowItem">
          <el-form-item name="item.level">
            <el-select v-model="item.level" class="item" clearable placeholder="请选择告警级别">
              <el-option :value="1" label="紧急告警"></el-option>
              <el-option :value="2" label="主要告警"></el-option>
              <el-option :value="3" label="次要告警"></el-option>
              <el-option :value="4" label="警告告警"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="item.productId" class="item" clearable filterable
                       placeholder="请选择产品类型" @change="productChange(index)">
              <el-option v-for="(item_d,index_d) in productList" :key="index_d" :label="item_d.name"
                         :value="item_d.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="data" name="item.dictId">
            <el-select v-model="item.dictId" class="item" filterable placeholder="请选择监控指标">
              <el-option v-for="(item_d,index_d) in dataList[index]" :key="index_d" :label="item_d.name"
                         :value="item_d.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="data" name="item.value">
            <el-input v-model="item.value" class="item" placeholder="请输入告警值">
              <template #prepend>
                <el-select v-model="item.symbol" class="item" placeholder="请选择">
                  <el-option label=">" value=">"></el-option>
                  <el-option label="<" value="<"></el-option>
                  <el-option label="=" value="="></el-option>
                  <el-option label=">=" value=">="></el-option>
                  <el-option label="<=" value="<="></el-option>
                </el-select>
              </template>
            </el-input>
          </el-form-item>

          <el-icon v-if="formData.targetJsonStr.length>1" class="delIcon" @click="delItemClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===formData.targetJsonStr.length-1" class="addIcon" @click="addItemClick">
            <CirclePlus/>
          </el-icon>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './alarmTemplate.less'


import {CloseBold, ZoomIn, Plus, Back, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  delAlarmTemplateAPi,
  getAlarmTemplateListAPi,
  getIndexSelectAPi,
  saveAlarmTemplateAPi, getProductSelectApi, getDictSelectApi
} from "@/api/modules/alarm";
import {getList} from "@/api/modules/productCategory";

let router = useRouter()


// 模版列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref(0)
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 表格数据
let tableData = ref([])
// 获取表格数据
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getAlarmTemplateListAPi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
  })
}


// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)
// 产品列表
let productList = ref([])
// 获取产品列表
const getProductListFun = function () {
  getProductSelectApi().then(res => {
    if (res.data.code === 0) {
      productList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const productChange = function (idx) {
  formData.value.targetJsonStr[idx].dictId = ''
  getDataListFun(idx)
}
// 指标列表
let dataList = ref([])
// 获取指标列表
const getDataListFun = function (idx) {
  getDictSelectApi(qs.stringify({
    productId: formData.value.targetJsonStr[idx].productId
  })).then(res => {
    if (res.data.code === 0) {
      dataList.value[idx] = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 新增编辑表单数据
let formData = ref({
  id: 0,
  name: '',
  targetJsonStr: [
    {
      level: '',
      dictId: '',
      symbol: '',
      value: '',
      productId: ''
    }
  ]
})
// 表单规则
let formRules = ref({
  name: [
    {required: true, message: '请输入模版名称', trigger: 'blur'},
  ],
  level: [
    {required: true, message: '请选择告警级别', trigger: 'blur'},
  ],
})
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value = {
    id: 0,
    name: '',
    targetJsonStr: [
      {
        level: '',
        dictId: '',
        symbol: '',
        value: '',
        productId: ''
      }
    ]
  }
}
// 点击编辑
const editClick = function (id, row) {
  dialogFormVisible.value = true
  formData.value = {
    id: row.id,
    name: row.name,
    targetJsonStr: JSON.parse(JSON.stringify(row.targetDList))
  }
  for (let i = 0; i < formData.value.targetJsonStr.length; i++) {
    getDataListFun(i)
  }
}
// 点击增加设备
const addItemClick = function () {
  formData.value.targetJsonStr.push({
    level: '',
    dictId: '',
    symbol: '',
    value: '',
    productId: ''
  })
}
// 点击删除设备
const delItemClick = function (index) {
  formData.value.targetJsonStr.splice(index, 1)
  dataList.value.splice(index, 1)
}
// 提交
let fromRef = ref()
const save = function () {
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      let data = JSON.parse(JSON.stringify(formData.value))
      let flag = false
      for (let i = 0; i < data.targetJsonStr.length; i++) {
        if (!data.targetJsonStr[i].productId || !data.targetJsonStr[i].dictId || !data.targetJsonStr[i].level || !data.targetJsonStr[i].symbol || !data.targetJsonStr[i].value) {
          flag = true
        }
      }
      if (!flag) {
        data.targetJsonStr = JSON.stringify(data.targetJsonStr)
        //提交成功
        if (valid) {
          saveAlarmTemplateAPi(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              getListFun()
              dialogFormVisible.value = false
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err);
          })
        }
      } else {
        ElMessage({
          type: 'info',
          message: '请输入必填项'
        })
      }
    })
  }
}

// 点击删除
const delClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要删除此告警模版，删除后数据不可恢复', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除模版',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此告警模版，删除后数据不可恢复'),
    ])
  }).then(() => {
    delAlarmTemplateAPi(qs.stringify({id: id})).then(res => {
      if (res.data.code === 0) {
        getListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}

let productCategoryList = ref([])  //数据间隔列表
onMounted(() => {
  getProductListFun()
  getListFun()

  // 获取产品分类
  getList(null).then(res => {
    if (res.data.ok) {
      productCategoryList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})


</script>

<style lang="less" scoped>
</style>
